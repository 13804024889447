import React from 'react';

function DescriptionSection() {
  return (
    <section className="description-section py-10 px-2">
      <div className="description-container max-w-768 ml-auto mr-auto text-center">
        <h2 className="description-title text-center text-lg">About Yoyuu</h2>
        <p className="description-text mt-3 opacity-70">yoyuu was birthed for those who are interested in exploring a lifestyle outside of societal conformity. offered as a sanctuary to experiment how to live as a commune—yoyuu's environment supports the practice of giving & receiving from a heart-centered space & caring for nature as an element that is part of us rather than separate from us. we are especially keen on using plants, ceremony & land stewardship to support us in slowing down so as to re-member how simplicity points us back to our divinity</p>
        <button className="mt-5 px-3 py-3 rounded-md lowercase bg-teal text-white description-button ">Peek Inside</button>
      </div>
    </section>
  );
}

export default DescriptionSection;
