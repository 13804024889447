import React from 'react';

function Navigation() {
  return (
    <nav className="navbar shadow-md">
      <div className="navbar-container py-2">
        <ul className="navbar-menu lowercase flex justify-between items-center p-3">
          <li className="navbar-item">
            <a href="/" className="navbar-link">Home</a>
          </li>
          <li className="navbar-item">
            <a href="/" className="navbar-link">Stay</a>
          </li>
          <li className="navbar-item">
            <a href="/" className="navbar-link">About</a>
          </li>
          <li className="navbar-item">
            <a href="/" className="navbar-link">Contact</a>
          </li>
        </ul>
      </div>
    </nav>

  );
}

export default Navigation;
