import React from 'react';

function VimeoSection() {
  return (
    <section className="vimeo-section py-10 px-2 bg-nice-pink">
      <div className="vimeo-container text-center max-w-768 ml-auto mr-auto">
        <iframe title='yoyuu moments' src="https://player.vimeo.com/video/780760840?h=8bd14cd42f" className='max-w-full' width='768' height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
    </section>
  );
}

export default VimeoSection;
