import React from 'react';

function Footer() {
  return (
    <footer className="footer py-10 px-2">
      <div className="footer-container ml-auto mr-auto text-center">
        <div className="footer-logo">
          <img className='ml-auto mr-auto' src='/assets/images/logo.png' alt='yoyuu logo' />
        </div>
        <p className="footer-text text-xs py-3 opacity-60">yoyuu.:. space & time that supports seasons of retreat & that prepare one to feel ready for where life takes them next</p>
      </div>
    </footer>
  );
}

export default Footer;
