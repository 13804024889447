import React from 'react';
import Navigation from './components/Navigation';
import HeroSection from './components/HeroSection';
import DescriptionSection from './components/DescriptionSection';
import VimeoSection from './components/VimeoSection';
import Footer from './components/Footer';

function App() {
  return (
    <main className="app">
      <Navigation />
      <HeroSection />
      <DescriptionSection />
      <VimeoSection />
      <Footer />
    </main>
  );
}

export default App;
