import React from 'react';

function HeroSection() {
  const styles = {
    background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.4)), url(/assets/images/hero.jpg) bottom center/cover no-repeat",
    height: "96vh"
  }
  return (
    <section className="hero-section">
      <div
        className="hero-container flex items-center justify-center bg-blend-darken"
        style={styles}
      >
        <div className='hero-text-content px-2 text-center max-w-lg text-white'>
          <h1 className="hero-title text-3xl mb-3">Yoyuu</h1>
          <p className='opacity-95'>space & time that supports seasons of retreat & that prepare one to feel ready for where life takes them next</p>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
